
import { Vue, Options, prop } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false,
  });
  apiVersion = prop<string>({
    type: String,
    default: "",
  });
}

@Options({
  emits: ["update:modelValue"],
  components: {
    ModalForm,
  },
})
export default class ModalDownloadMutation extends Vue.with(Props) {
  onClose() {
    this.onReset();
    this.setModalDownloadMutation(false);
  }

  onReset() {
    this.activityName = "";
    this.activityValue = "";
  }

  setModalDownloadMutation(boolean: boolean) {
    BalanceController.setModalDownloadMutation(boolean);
  }

  // shortland based
  activityName = "";
  activityValue = "";
  get activityData() {
    return [
      { name: this.$t("balance.activityPlaceholder"), value: "" },
      { name: "All", value: "all" },
      { name: "Debit", value: "debit" },
      { name: "Credit", value: "credit" },
    ];
  }
  activitySelect = false;
  onOpenActivitySelect() {
    this.activitySelect = true;
  }
  onCloseActivitySelect() {
    this.activitySelect = false;
  }
  onSelectActivity(name: string, value: string) {
    this.activityName = name;
    this.activityValue = value;
    this.onCloseActivitySelect();
  }

  // date
  startDate = "";
  endDate = "";

  setPeriode(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    this.startDate = value[0];
    this.endDate = value[1];
  }
  get converDate() {
    return `${dateToDateString(this.startDate, true)}`;
  }

  // filter date
  minDate: any = null;
  maxDate: any = null;
  tempStartDate: any = null;
  tempEndDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempStartDate = this.startDate;
      this.tempEndDate = this.endDate;
      this.startDate = "";
      this.endDate = "";
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 29)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 29)
      );
      this.isStartDate = false;
    } else {
      this.endDate && (this.isStartDate = true);
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      this.isStartDate = true;
      this.tempStartDate = null;
      this.tempEndDate = null;
    }
  }

  donwloadBalanceMutation() {
    BalanceController.getDownloadBalanceReportMutation({
      activity: this.activityValue === "all" ? "" : this.activityValue,
      startDate: new Date(this.startDate).toLocaleDateString("fr-CA"),
      endDate: new Date(this.endDate).toLocaleDateString("fr-CA"),
      transactionType: "",
      actorId: "",
      apiVersion: this.apiVersion,
    });
    this.onReset();
  }
}
